import http from '@/plugins/http-common'
import {OrderByVariants} from './constants'
import {ResponseData} from './types'

export type GetListParams = {
    limit: null | number,
    offset: null | number,
    order_by: null | OrderByVariants,
    sort_by: null | string,
    agent_id: null | string,
    status_id: null | number,
    search_fio: null | string,
    search_email: null | string,
    search_ref: null | string,
    search_promo: null | string,
    search_phone: null | string,
    search_inn: null | string,
}

class OrdersService {
    getList = (params: GetListParams): Promise<ResponseData> => http.get('/orders', {params})

    getExport = (orderStatusId: number, exportedAtFrom?: string, exportedAtTo?: string): Promise<ResponseData> =>
        http.post('/orders/export', {
            status_id: orderStatusId,
            exported_at_from: exportedAtFrom,
            exported_at_to: exportedAtTo,
        })

    process = (order: number): Promise<ResponseData> =>
        http.post(`/orders/${order}/process`, {order})

    pay = (order: number): Promise<ResponseData> =>
        http.post(`/orders/${order}/pay`, {order})

    error = (order: number): Promise<ResponseData> =>
        http.post(`/orders/${order}/error`, {order})

    revert = (order: number): Promise<ResponseData> =>
        http.post(`/orders/${order}/revert`, {order})
}

export default new OrdersService()