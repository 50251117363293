import {User} from '@/composables/user/user-models'
import {AgentShort} from '@/composables/agents/agents-models'

export interface OrdersModels {
    id: number,
    amount: number,
    created_at: string,
    exported_at: string,
    bic: string,
    check_acc: string,
    is_limit: boolean,
    user: User,
    agent: AgentShort,
    status: OrdersStatus,
}

export interface OrdersStatus {
    id: number,
    name: string,
    system_name: string,
    is_lock: boolean,
}

export interface Statistic {
    orders: OrdersStatistic,
    balance: BalanceStatistic,
    orders_paid: OrdersPaidStatistic,
}

export interface OrdersStatistic {
    requested: number,
    current: number,
    last_year: number,
}

export interface OrdersPaidStatistic {
    total: number,
    last_year: number,
}

export interface BalanceStatistic {
    requested: number,
    current: number,
    process: number,
    last_year: number,
}

type OrderStatus = {
    name: OrderStatusName,
    id: OrderStatusId
}

export enum OrderStatusName {
    CREATED = 'Заказ на вывод',
    IN_PROCESS = 'В процессе',
    PAID = 'Выплачено',
    ERROR = 'Ошибка',
    REVERTED = 'Возвращен',
    EXPORTED = 'Выгружен в excel',
}

 export enum OrderStatusId {
    CREATED = 1,
    IN_PROCESS = 2,
    PAID = 3,
    ERROR = 4,
    REVERTED = 5,
    EXPORTED = 6,
}

export const OrdersStatuses: OrderStatus[] = [
    {
        name: OrderStatusName.CREATED,
        id: OrderStatusId.CREATED
    },
    {
        name: OrderStatusName.IN_PROCESS,
        id: OrderStatusId.IN_PROCESS
    },
    {
        name: OrderStatusName.PAID,
        id: OrderStatusId.PAID
    },
    {
        name: OrderStatusName.ERROR,
        id: OrderStatusId.ERROR
    },
    {
        name: OrderStatusName.REVERTED,
        id: OrderStatusId.REVERTED
    },
    {
        name: OrderStatusName.EXPORTED,
        id: OrderStatusId.EXPORTED
    }
]