import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Datepicker = _resolveComponent("Datepicker")!

  return (_openBlock(), _createBlock(_component_Datepicker, {
    modelValue: _ctx.date,
    format: _ctx.ctx.format,
    maxDate: _ctx.maxDate,
    enableTimePicker: false,
    autoApply: "",
    locale: "ru",
    "onUpdate:modelValue": _ctx.ctx.handleUpdate,
    position: "center",
    placeholder: "Выберите дату"
  }, null, 8, ["modelValue", "format", "maxDate", "onUpdate:modelValue"]))
}