
import moment from 'moment'
import { Options, setup, Vue } from 'vue-class-component'
import Datepicker from '@vuepic/vue-datepicker'
import '@vuepic/vue-datepicker/dist/main.css'

@Options({
  props: {
    date: Date,
  },
  components: {
    Datepicker
  },
})

export default class DatePicker extends Vue {
    private maxDate = new Date()
    date!: Date

    ctx = setup(() => {
      const handleUpdate = (newDate: Date) => {
        this.$emit('update', newDate)
      }
      const format = (date: Date) => {
        return moment(date).format('DD.MM.YYYY')
      }

      return {
        format,
        handleUpdate
      }
    })
}
